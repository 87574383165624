import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Bar from "./bar"
import Helmet from "react-helmet"

const useStyles = makeStyles(theme => ({
  spacer: {
    height: "10vh",
  },
}))

export default function Layout(props) {
  const classes = useStyles()
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Code&family=Jost&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Bar name="jaack.page" tabs tabIndex={props.tabIndex} />
      <div className={classes.spacer} />
      <>{props.children}</>
    </>
  )
}
