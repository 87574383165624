import React, { useState } from "react"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
  Container,
  Grid,
  Tabs,
  Tab,
  IconButton,
  withStyles,
} from "@material-ui/core"
import { Link } from "gatsby"

import JackIcon from "./assets/Jack.inline.svg"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "transparent",
    boxShadow: "none",
    postion: "absolute",
  },
  drawer: {
    background: `#9796f0`,
    postion: "relative",
    color: "white",
  },
  menuButton: {
    marginRight: theme.spacing(1),
    color: "white",

    "&:hover": {
      animation: "rotate 2s infinite",
    },
  },
  jost: {
    fontFamily: "Jost",
  },
  button: {
    fontFamily: "Jost",
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontFamily: "Jost",
  },
  cvLink: {
    textDecoration: "none",
    color: "black",
    fontFamily: "Fira Code",
    fontSize: "1rem",
  },
  icon: {
    color: "white",
  },
  box: {
    float: "right",
  },
  divider: {
    borderTop: "1px solid white",
  },
  cvButton: {
    fontFamily: "Fira Code",
    position: "absolute",
    right: "2rem",
    fontSize: "1rem",
  },
}))

const StyledTab = withStyles({
  wrapper: {
    fontFamily: "Fira Code",
    fontSize: "1rem",
  },
})(Tab)

function Bar(props) {
  const initialTabIndex = props.tabIndex
  const [tabIndex, setTabIndex] = useState(initialTabIndex)
  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 600px)")
  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <JackIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.link} to="/">
              {props.name}
            </Link>
          </Typography>

          {props.cvButton && (
            <Link to="/cv" className={classes.cvLink}>
              <Button className={classes.cvButton} variant="contained">
                View CV
              </Button>
            </Link>
          )}
        </Toolbar>
        {props.tabs && (
          <Tabs
            variant={isPhone ? "fullWidth" : "standard"}
            value={tabIndex}
            onChange={handleChange}
            centered={isPhone === false && true}
          >
            <StyledTab component="a" label="About" href="/" />
            <StyledTab component="a" label="CV / Contact" href="/cv/" />
          </Tabs>
        )}
        {props.contactDetails && (
          <>
            <Grid container alignItems="center" justify="space-evenly">
              <Grid item>jack.burns314@gmail.com</Grid>
              <Grid item>https://www.jaack.page</Grid>
            </Grid>
          </>
        )}
      </AppBar>
    </div>
  )
}
export default Bar
